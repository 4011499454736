import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonBox = ({
  imageHeight = 150,
  titleWidth = '60%',
  contentCount = 3,
  buttonWidth = '0%',
  priceWidth = '50%',
}) => (
  <div className="skeleton-box" style={styles.box}>
    <Skeleton height={imageHeight} style={styles.image} />
    <Skeleton width={titleWidth} height={20} style={styles.title} />
    {[...Array(contentCount)].map((_, index) => (
      <Skeleton key={index} width="100%" height={15} style={styles.content} />
    ))}
    <div style={styles.footer}>
      <Skeleton width={buttonWidth} height={30} style={styles.button} />
      <Skeleton width={priceWidth} height={20} style={styles.price} />
    </div>
  </div>
);

const SkeletonLoader = ({ count = 5 }) => {
  let columnClass = '';
  switch (count) {
    case 4:
      columnClass = 'col-lg-3 col-md-3 col-sm-3 xol-xs-3 col-3';
      break;
    case 3:
      columnClass = 'col-lg-4 col-md-4 col-sm-4 xol-xs-4 col-4';
      break;
    case 2:
      columnClass = 'col-lg-6 col-md-6 col-sm-6 xol-xs-6 col-6';
      break;
    case 1:
      columnClass = 'col-lg-12 col-md-12 col-sm-12 xol-xs-12 col-12';
      break;
    default:
      columnClass = 'col-lg-3 col-md-3 col-sm-3 xol-xs-3 col-3'; // Default case, adjust as needed
      break;
  }
  return (
    <div className="row skeleton-loader" style={styles.container}>
      {[...Array(count)].map((_, index) => (
        <div key={index} className={columnClass}>
          <SkeletonBox
            imageHeight={300}
            titleWidth="100%"
            contentCount={4}
            buttonWidth="50%"
            priceWidth="50%"
          />
        </div>
      ))}
    </div>
  );
};

const styles = {
  box: {
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  image: {
    marginBottom: '15px',
  },
  title: {
    marginBottom: '10px',
  },
  content: {
    marginBottom: '10px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    marginRight: '10px',
  },
  price: {
    textAlign: 'right',
  },
};

export default SkeletonLoader;