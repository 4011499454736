import { useEffect } from "react";
import axios from "axios";
import { Apis } from './index';
export const HeaderSearch = ({ searchItem, onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        if (typeof searchItem === 'string' && searchItem.length >= 3) {
            const fetchSearch = async () => {
                try {
                    const response = await axios.get(Apis.PackageSearch, {
                        params: { searchitems: searchItem },
                        headers: { 'Authorization': apiKey }
                    });
                    onFetch(response.data);
                } catch (error) {
                    console.error(error);
                    onFetch([], false);
                }
            };
            fetchSearch();
        }
    }, [searchItem, onFetch, apiKey]);
    return null;
}
