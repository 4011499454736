import { useEffect } from "react";
import axios from "axios";
import { Apis } from './index';

export const TrandingDestinations = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchDestinations = async () => {
            try {
                const response = await axios.get(Apis.TrandingDestinations, {
                    headers: { 'Authorization': apiKey }
                });
                if (onFetch) {
                    onFetch(response.data.regions);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchDestinations();
    }, [apiKey, onFetch]);

    return null;
};
export const AllCountry = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const response = await axios.get(Apis.Allcountry, {
                    headers: { 'Authorization': apiKey }
                });
                if (onFetch) {
                    onFetch(response.data.countries);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchCountry();
    }, [apiKey, onFetch]);
    return null;
    
};
export const LimitedDestinations = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchLimitedDestinations = async () => {
            try {
                const response = await axios.get(Apis.LimitedDestinations, {
                    headers: { 'Authorization': apiKey }
                });
                if (onFetch) {
                    onFetch(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchLimitedDestinations();
    }, [apiKey, onFetch]);
    return null;
};
