import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import "./faturedin.css";
import { Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import axios from "axios";
import { Apis } from '../../Api/index';

export default function Featured(){
    const [featuredin, setFeatured] = useState([null]);
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchFeatured = async () => {
            try {
                const response = await axios.get(Apis.Featured, {
                    headers: { 'Authorization': apiKey }
                });
                setFeatured(response.data.featured);
            } catch (error) {
                console.error(error);
            }
        };
        fetchFeatured();
    }, [apiKey]);
    
    const [recognized, setRecognized] = useState([null]);
    useEffect(() => {
        const fetchRecognized = async () => {
            try {
                const response = await axios.get(Apis.Recognized, {
                    headers: { 'Authorization': apiKey }
                });
                setRecognized(response.data.recognized);
            } catch (error) {
                console.error(error);
            }
        };
        fetchRecognized();
    }, [apiKey]);
    return(
        <>
        <section className="featuredsection">
            <div className="container-fluid">
                <div className="w90">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="featuredgridbox">
                                <div className="featuredheaing">
                                    <h2>Featured in</h2>
                                </div>
                                <div className="featureddata">
                                    <div className="fatured-prev navarrowprev"><FaChevronLeft/></div>
                                    <div className="fatured-next navarrownext"><FaChevronRight/></div>
                                    <Swiper modules={[Navigation, A11y]} spaceBetween={50} slidesPerView={2}
                                    breakpoints={{
                                        0: {
                                        slidesPerView: 1,
                                        },
                                        400: {
                                        slidesPerView: 1,
                                        },
                                        639: {
                                        slidesPerView: 2,
                                        },
                                        865: {
                                        slidesPerView: 2,
                                        },
                                        1000: {
                                        slidesPerView: 2,
                                        },
                                        1500: {
                                        slidesPerView: 2,
                                        },
                                        1700: {
                                        slidesPerView: 2,
                                        }
                                    }}
                                    navigation={{
                                        prevEl: '.fatured-prev',
                                        nextEl: '.fatured-next',
                                    }}
                                    >
                                        {featuredin.map((featured) => featured && (
                                            <SwiperSlide key={featured.id}>
                                                <div className="faturedbox">
                                                    <Link to={featured.partner_url} target="_blank">
                                                        <img src={featured.partner_img} alt="" />
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-5">
                            <div className="featuredgridbox">
                                <div className="featuredheaing">
                                    <h2>Recognized by</h2>
                                </div>
                                <div className="featureddata">
                                    <div className="recor-prev navarrowprev"><FaChevronLeft/></div>
                                    <div className="recor-next navarrownext"><FaChevronRight/></div>
                                    <Swiper modules={[Navigation, A11y]} spaceBetween={50} slidesPerView={2}
                                    breakpoints={{
                                        0: {
                                        slidesPerView: 1,
                                        },
                                        400: {
                                        slidesPerView: 1,
                                        },
                                        639: {
                                        slidesPerView: 2,
                                        },
                                        865: {
                                        slidesPerView: 2,
                                        },
                                        1000: {
                                        slidesPerView: 2,
                                        },
                                        1500: {
                                        slidesPerView: 2,
                                        },
                                        1700: {
                                        slidesPerView: 2,
                                        }
                                    }}
                                    navigation={{
                                        prevEl: '.recor-prev',
                                        nextEl: '.recor-next',
                                    }}
                                    >
                                    {recognized.map((recogniz) => recogniz && (
                                        <SwiperSlide key={recogniz.id}>
                                            <div className="faturedbox">
                                                <Link to={recogniz.partner_url} target="_blank">
                                                    <img src={recogniz.partner_img} alt="" />
                                                </Link>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}