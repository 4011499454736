import React, { useEffect, useState, useRef } from "react";
import './navbar.css';
import { Link, useLocation } from "react-router-dom";
import MainLogo from "../../assets/main-logo.png";
import { FaBarsStaggered } from "react-icons/fa6";
import { FaTimes, FaRegUserCircle, FaPhoneAlt, FaRegCommentDots, FaSearch, FaRocketchat, FaWhatsapp, FaEnvelope, FaFacebookF, FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa";
import { ReactComponent as ShortBreakIcon } from "../../assets/short_break.svg";
import { ReactComponent as CorporateIcon } from "../../assets/corporate.svg";
import { ReactComponent as LuxuryIcon } from "../../assets/luxury.svg";
import { ReactComponent as AdventureIcon } from "../../assets/adventure.svg";
import { ReactComponent as VisaIcon } from "../../assets/visa.svg";
import { LimitedDestinations } from "../../Api/destinations";
import { HeaderSearch } from "../../Api/search"

export default function Navbar() {
    const [destinations, setDestinations] = useState([]);
    const [navSticky, setNavSticky] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const location = useLocation();
    useEffect(() => {
        const scrollHandler = () => {
            setNavSticky(window.scrollY > 50);
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [location.pathname]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target) &&
                divRef.current && !divRef.current.contains(event.target)) {
                setIsVisible(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(prev => {
            const newState = !prev;
            document.body.classList.toggle('no-scroll', newState);
            return newState;
        });
    };
    const toggleSidebarClose = () => {
        setIsSidebarOpen(false);
        document.body.classList.remove('no-scroll');
    };
    /* ------------------ Search Form ------------------------*/
    const [searchTerm, setSearchTerm] = useState('');
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };  
    /* ------------------- when click search box then open hint -------------*/
    const [isVisible, setIsVisible] = useState(false);
    const inputRef = useRef(null);
    const divRef = useRef(null);
    const handleInputClick = () => {
        setIsVisible(true);
    };
    /*-------------------- Mobile toggles ---------------*/
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <section className="tophearder">
                <div className="container-fluid">
                    <div className="w90">
                        <div className="topbararea">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                                    {/* <div className="topheaderleft">
                                        <ul>
                                            <li><Link href="#">Country</Link></li>
                                            <li><Link href="#">Language</Link></li>
                                            <li><Link href="#">Currency</Link></li>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                                    <div className="topheaderright">
                                        <ul>
                                            <li><Link href="https://fayyaztravels.com/login/" target="_blank"><FaRegUserCircle /> Login</Link></li>
                                            <li><Link href="tel:0208 12444337"><FaPhoneAlt /> 0208 12444337</Link></li>
                                            <li><Link href="https://fayyaztravels.com/contact-us/" target="_blank"><FaRegCommentDots /> Contact us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`${navSticky ? 'nav-sticky' : ''} mainmenu desktopmenu`}>
                <div className="container-fluid">
                    <div className="w90">
                        <div className="menugrids">
                            {!navSticky && (
                                <div className="menulogo">
                                    <img src={MainLogo} alt="Main Logo" />
                                </div>
                            )}
                            {navSticky && windowWidth > 800 && (
                                <div className="sidebarmenudata">
                                    <button type="button" onClick={toggleSidebar}><FaBarsStaggered /></button>
                                </div>
                            )}
                            <div className="menuareas">
                                <ul>
                                    <li><Link to="https://fayyaztravels.com/packages/" target="_blank">Packages</Link></li>
                                    <li><Link to="https://fayyaztravels.com/hot-deals/" target="_blank">Deals</Link></li>
                                    <li><Link to="https://fayyaztravels.com/tailor-made-luxury-holidays/" target="_blank">Luxury</Link></li>
                                    <li><Link to="https://fayyaztravels.com/corporate-travel-agents-singapore/" target="_blank">Mice</Link></li>
                                    <li><Link to="https://fayyaztravels.com/visa/" target="_blank">Visa</Link></li>
                                    <li><Link to="https://fayyaztravels.com/blog/" target="_blank">Inspo</Link></li>
                                    <li><Link to="https://fayyaztravels.com/about/" target="_blank">About</Link></li>
                                </ul>
                            </div>
                            {!navSticky && (
                                <div className="searchbarrightmiddel">
                                    <div className="formserarch">
                                        <input type="search" id="searchform" name="search" placeholder="where’s your next trip?" value={searchTerm} onChange={handleInputChange} onClick={handleInputClick} ref={inputRef} autocomplete="off" />
                                        <button type="button"><FaSearch /></button>
                                    </div>
                                </div>
                            )}
                            {navSticky && (
                                <div className="menulogo mobilelogo">
                                    <Link to="/"><img src={MainLogo} alt="Main Logo" /></Link>
                                </div>
                            )}
                            {windowWidth < 800 && (
                                <div className="sidebarmenudata mobiletootle">
                                    <button type="button" onClick={toggleSidebar}><FaBarsStaggered /></button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {isVisible && !navSticky && (
                    <div className="searchreasultarea" ref={divRef}>
                        <div className="searchresults">
                            {searchTerm.length < 3 && (
                                <LimitedDestinations onFetch={setDestinations} />
                            )}
                            <HeaderSearch searchItem={searchTerm} onFetch={setDestinations} />
                            {destinations?.regions && destinations.regions.length > 0 ? (
                                <div className="regionsearch">
                                    <h2>Regions</h2>
                                    <div className="regionlist">
                                        {destinations.regions.map((destination) => (
                                            destination && (
                                                <div className="regionitem" key={destination.id}>
                                                    <Link to={destination.regison_url} target="_blank">
                                                        <div className="regionimg">
                                                            <img src={destination.regison_image} alt={destination.regison_name} />
                                                        </div>
                                                        <div className="regionname">
                                                            <span>{destination.regison_name}</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            ) : null}    
                            {destinations?.country && destinations.country.length > 0 ? (
                                <div className="regionsearch">
                                    <h2>Country</h2>
                                    <div className="regionlist">
                                        {destinations.country.map((country) => (
                                            country && (
                                                <div className="regionitem" key={country.id}>
                                                    <Link to={country.regison_url} target="_blank">
                                                        <div className="regionimg">
                                                            <img src={country.regison_image} alt={country.regison_name} />
                                                        </div>
                                                        <div className="regionname">
                                                            <span>{country.regison_name}</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            ) : null}      
                            {destinations?.packages && destinations.packages.length > 0 ? (
                                <div className="regionsearch">
                                    <h2>Packages</h2>
                                    <div className="regionlist packagesearch">
                                        {destinations.packages.map((packages) => (
                                            packages && (
                                                <div className="regionitem" key={packages.id}>
                                                    <Link to={packages.package_url} target="_blank">
                                                        <div className="regionimg">
                                                            <img src={packages.package_img} alt={packages.package_name} />
                                                        </div>
                                                        <div className="regionname">
                                                            <span>{packages.package_name}</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            ) : null}                                          
                        </div>
                    </div>
                )}
            </section>
            {(navSticky || windowWidth < 800) && (
                <div className={`sidebardata ${isSidebarOpen ? 'sidebaropen' : 'sidebarclose'}`}>
                    <div className="sidebargris">
                        <button className="sidebarclose close_menu" onClick={toggleSidebarClose}><FaTimes /></button>
                        <h2 className="sidebartitle">Where memories start</h2>
                        <div className="sidebarscroll">
                            {windowWidth < 800 && (
                                <div className="mobilemenu">
                                    <ul>
                                        <li><Link to="https://fayyaztravels.com/packages/" target="_blank">Packages</Link></li>
                                        <li><Link to="https://fayyaztravels.com/hot-deals/" target="_blank">Deals</Link></li>
                                        <li><Link to="https://fayyaztravels.com/tailor-made-luxury-holidays/" target="_blank">Luxury</Link></li>
                                        <li><Link to="https://fayyaztravels.com/corporate-travel-agents-singapore/" target="_blank">Mice</Link></li>
                                        <li><Link to="https://fayyaztravels.com/visa/" target="_blank">Visa</Link></li>
                                        <li><Link to="https://fayyaztravels.com/blog/" target="_blank">Inspo</Link></li>
                                        <li><Link to="https://fayyaztravels.com/about/" target="_blank">About</Link></li>
                                    </ul>
                                </div>
                            )}
                            {/* <div className="sidebarsearch">
                                <div className="formserarch">
                                    <input type="search" name="search" placeholder="where’s your next trip?" />
                                    <button type="submit"><FaSearch /></button>
                                </div>
                            </div> */}
                            <div className="sidebardestination">
                                <div className="sidebardestilist">
                                    <Link to="https://fayyaztravels.com/short-breaks-in-asia/" target="_blank">
                                        <div className="destiname">
                                            <h3>Short breaks</h3>
                                        </div>
                                        <div className="destiicon"><ShortBreakIcon /></div>
                                    </Link>
                                </div>
                                <div className="sidebardestilist">
                                    <Link to="https://fayyaztravels.com/corporate-travel-agents-singapore/" target="_blank">
                                        <div className="destiname">
                                            <h3>Corporate</h3>
                                        </div>
                                        <div className="destiicon"><CorporateIcon /></div>
                                    </Link>
                                </div>
                                <div className="sidebardestilist">
                                    <Link to="https://fayyaztravels.com/tailor-made-luxury-holidays/" target="_blank">
                                        <div className="destiname">
                                            <h3>Luxury</h3>
                                        </div>
                                        <div className="destiicon"><LuxuryIcon /></div>
                                    </Link>
                                </div>
                                <div className="sidebardestilist">
                                    <Link to="https://fayyaztravels.com/adventure-packages/" target="_blank">
                                        <div className="destiname">
                                            <h3>Adventure</h3>
                                        </div>
                                        <div className="destiicon"><AdventureIcon /></div>
                                    </Link>
                                </div>
                                <div className="sidebardestilist">
                                    <Link to="https://fayyaztravels.com/visa/" target="_blank">
                                        <div className="destiname">
                                            <h3>Visa</h3>
                                        </div>
                                        <div className="destiicon"><VisaIcon /></div>
                                    </Link>
                                </div>
                            </div>
                            <div className="sidebarsocialmedia">
                                <div className="socialmediatitle">
                                    <h3>Contact us</h3>
                                </div>
                                <div className="socialicons">
                                    <Link to="#"><FaPhoneAlt /></Link>
                                    <Link to="#"><FaRocketchat /></Link>
                                    <Link to="#" target="_blank"><FaWhatsapp /></Link>
                                    <Link to="#"><FaEnvelope /></Link>
                                </div>
                            </div>
                            <div className="sidebarsocialmedia">
                                <div className="socialmediatitle">
                                    <h3>Follow us</h3>
                                </div>
                                <div className="socialicons">
                                    <Link to="https://www.facebook.com/FayyazTravels/" target="_blank"><FaFacebookF /></Link>
                                    <Link to="https://www.instagram.com/fayyaztravels/" target="_blank"><FaInstagram /></Link>
                                    <Link to="https://www.youtube.com/channel/UCjD1tI0YGDQQXqbfhYXP91g" target="_blank"><FaYoutube /></Link>
                                    <Link to="https://www.tiktok.com/@fayyaztravels" target="_blank"><FaTiktok /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}