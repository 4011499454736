
const API_URL = "https://fayyaztravels.com";
// const API_URL = "http://localhost/fayyaz";
const APP_URL = "http://fayyaz-uk.driveitdigital.in";

const Apis = {
  TrandingDestinations: `${API_URL}/api/tranding-destination`,
  HotDeals: `${API_URL}/api/hot-deals`,
  Testimonials: `${API_URL}/api/testimonials`,
  Featured: `${API_URL}/api/featured-partner`,
  Recognized: `${API_URL}/api/recognized-partner`,
  InstagramFeed: `${API_URL}/api/instagram-feed`,
  Allcountry: `${API_URL}/api/all-country`,
  LimitedDestinations: `${API_URL}/api/limited-destination`,
  PackageSearch: `${API_URL}/api/search-package`,
};
export { API_URL, Apis, APP_URL };
