import React, { useState, useRef, useEffect } from 'react';
import './banner.css';
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { FaMapMarkerAlt, FaSearch, FaFlag } from "react-icons/fa";
import Notfound from '../../assets/notfound.webp';
import { AllCountry } from "../../Api/destinations";

function Banner() {
    const [Countylist, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isCountryListVisible, setIsCountryListVisible] = useState(false);
    const countryListRef = useRef(null);

    const handleCountryClick = (country) => {
        setSelectedCountries(prev =>
            prev.includes(country) ? prev.filter(c => c !== country) : [...prev, country]
        );
    };

    const handleRemoveCountry = (country) => {
        setSelectedCountries(prev => prev.filter(c => c !== country));
    };

    const handleReset = () => {
        setSelectedCountries([]);
    };

    const handleInputClick = () => {
        setIsCountryListVisible(true);
    };

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        const filtered = Countylist.filter(country =>
            country.country_name.toLowerCase().includes(query)
        );

        setCountries(filtered);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryListRef.current && !countryListRef.current.contains(event.target)) {
                setIsCountryListVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const applyCount = selectedCountries.length;
    const displayCount = applyCount > 3 ? `+${applyCount - 3}` : applyCount;

    return (
        <>
            <AllCountry onFetch={setCountries} />
            <section className="mainbanner">
                <div className="container">
                    <div className="bannercontent">
                        <h2>Save Big on Your Next Journey!</h2>
                        <p>Special Rates Ending Soon</p>
                        <div className="bannerbtns">
                            <Link to="#">Explore More</Link>
                        </div>
                    </div>
                    <div className="bannerforms">
                        {/* <form method="post" action="">
                            <div className="row g-0">
                                <div className="col-lg-7 col-md-4 col-sm-12 col-xs-12 col-4">
                                    <div className="form-group bannerformgoup">
                                        <div className="search-bar">
                                            <div className="bannerformicon"><FaMapMarkerAlt /></div>
                                            <div className='input_wrapper d-flex justify-content-center align-items-center'>
                                                <input type="text" className='form-control' id="searchInput" placeholder="Search for a place" value={selectedCountries.map(c => c.country_name).join(', ')} onClick={handleInputClick} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12 col-4">
                                    <div className="form-group bannerformgoupse">
                                        <div className="bannerformicon"> <FaSearch /></div>
                                        <input type="text" className="form-control" placeholder="Keywords" />
                                    </div>
                                </div>
                            </div>
                            <div className="bannerformsubmit">
                                <button type="submit">Submit</button>
                            </div>
                        </form> */}

                        {isCountryListVisible && (
                            <div className='country_search' ref={countryListRef}>
                                <div id="selectedCountries" className='tag-input-container d-flex w-100 justify-content-between' onClick={handleInputClick}>
                                    <div className='d-flex justify-content-start gap-2 align-items-center'>
                                        {selectedCountries.slice(0, 3).map(country => (
                                            <div key={country.id} className="selected-country">
                                                <span>{country.country_name}</span>
                                                <button type="button" className="remove-country" onClick={() => handleRemoveCountry(country)}>&times;</button>
                                            </div>
                                        ))}
                                        {selectedCountries.length > 3 && (
                                            <div className="more-countries">+{selectedCountries.length - 3}</div>
                                        )}
                                        <div className='search_countries d-flex align-items-center'>
                                            <CiSearch />
                                            <input type="text" className="country-search-input" placeholder="Search for a place..." value={searchQuery} onChange={handleSearchChange} />
                                        </div>
                                    </div>

                                    <div className="apply-reset-container d-flex gap-2 justify-content-end mt-2">
                                        <button className="btn-reset text-decoration-underline fw-bold border-0 bg-transparent" onClick={handleReset}>RESET</button>
                                        <button className="btn-apply">APPLY ({displayCount})</button>
                                    </div>
                                </div>

                                <div className="country-list overflow-auto">
                                   <div className="container">
                                   <div className="row">
                                        {Countylist.length === 0 ? (
                                            <div className="col-md-12">
                                                <div className="no-results-message text-center">
                                                    <img src={Notfound} alt="notfound" />
                                                    <p className='mb-0'>No Results Found</p>
                                                </div>
                                            </div>
                                        ) : (
                                            Countylist.map((country) => (
                                                <div className="col-md-3" key={country.id}>
                                                    <div className={`country-item ${selectedCountries.includes(country) ? 'active' : ''}`}
                                                        onClick={() => handleCountryClick(country)}>
                                                        <span className='flag_icon' role='button'><FaFlag /></span>
                                                        <div className='country_name'>{country.country_name}</div>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                   </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner;
