import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaXTwitter, FaLinkedinIn, FaYoutube, FaPinterest, FaTiktok } from "react-icons/fa6";
import footerlogo from "../../assets/whitelogo.png";

export default function Footer(){
    return(
        <>
        <footer className="mainfooter">
            <div className="container-fluid">
                <div className="w90">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 co-sm-6 col-xs-6 col-6">
                                    <div className="footerwidget">
                                        <h2>General</h2>
                                        <ul>
                                            <li><Link to="https://fayyaztravels.com/about/" target="_blank">About us</Link></li>
                                            <li><Link to="https://fayyaztravels.com/contact-us/" target="_blank">Contact us</Link></li>
                                            <li><Link to="https://fayyaztravels.com/visa/" target="_blank">Visa</Link></li>
                                            <li><Link to="https://fayyaztravels.com/csr/" target="_blank">Corporate Social Responsibility</Link></li>
                                            <li><Link to="https://fayyaztravels.com/testimonials/" target="_blank">Testimonials</Link></li>
                                        </ul>
                                    </div>
                                    <div className="footerwidget mb0">
                                        <h2>Covid Policies</h2>
                                        <ul>
                                            <li><Link to="https://www.mfa.gov.sg/Services/Singapore-Citizens/COVID-19-Travel-Restrictions/" target="_blank">Singapore Govn't Travel Advice</Link></li>
                                            <li><Link to="https://u.ae/en/information-and-services/justice-safety-and-the-law/handling-the-covid-19-outbreak/travelling-amid-covid-19/travelling-to-the-uae/" target="_blank">UAE Travel Advice</Link></li>
                                            <li><Link to="https://www.gov.uk/uk-border-control" target="_blank">UK Travel Advice</Link></li>
                                            <li><Link to="https://travel.state.gov/content/travel/en/traveladvisories/traveladvisories.html/" target="_blank">US Travel Advice</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 co-sm-6 col-xs-6 col-6">
                                    <div className="footerwidget">
                                        <h2>Short Breaks in Asia</h2>
                                        <ul>
                                            <li><Link to="https://fayyaztravels.com/package/panaromic-paradise-kashmir-fixed-departures-march-to-april-2024/" target="_blank">Kashmir Holidays</Link></li>
                                            <li><Link to="https://fayyaztravels.com/package/sri-lanka-cultural-highlights-4-day-historic-and-scenic-tour/" target="_blank">Sri Lanka Holidays</Link></li>
                                            <li><Link to="https://fayyaztravels.com/package/jeju-and-seoul-escapade/" target="_blank">South Korea Holidays</Link></li>
                                            <li><Link to="https://fayyaztravels.com/package/honeymoon-escapade-at-hard-rock-hotel-maldives/" target="_blank">Maldives Holidays</Link></li>
                                        </ul>
                                    </div>
                                    <div className="footerwidget mb0">
                                        <h2>Scandinavian Experiences</h2>
                                        <ul>
                                            <li><Link to="https://fayyaztravels.com/destinations/scandinavia/norway/" target="_blank">Norway Tour Packages</Link></li>
                                            <li><Link to="https://fayyaztravels.com/destinations/europe/finland/" target="_blank">Finland Tour Packages</Link></li>
                                            <li><Link to="https://fayyaztravels.com/destinations/scandinavia/sweden/" target="_blank">Sweden Tour Packages</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 co-sm-6 col-xs-6 col-6">
                                    <div className="footerwidget formobiegap">
                                        <h2>Luxury Holiday Packages</h2>
                                        <ul>
                                            <li><Link to="https://fayyaztravels.com/destinations/the-caribbean/cuba/" target="_blank">Private Tours in Cuba</Link></li>
                                        </ul>
                                    </div>
                                    <div className="footerwidget mb0">
                                        <h2>Europe Holidays</h2>
                                        <ul>
                                            <li><Link to="https://fayyaztravels.com/destinations/south-east-europe/bosnia-herzegovina/" target="_blank">Bosnia & Herzegovina</Link></li>
                                            <li><Link to="https://fayyaztravels.com/destinations/south-east-europe/bulgaria/" target="_blank">Bulgaria</Link></li>
                                            <li><Link to="https://fayyaztravels.com/destinations/south-east-europe/croatia/" target="_blank">Croatia</Link></li>
                                            <li><Link to="https://fayyaztravels.com/destinations/south-east-europe/greece/" target="_blank">Greece</Link></li>
                                            <li><Link to="https://fayyaztravels.com/destinations/europe/italy/" target="_blank">Italy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footerwidget formobiegap">
                                <h2>Get in Touch</h2>
                                <p><Link to="mailto:info@fayyaztravels.com">info@fayyaztravels.com</Link></p>
                                <p>Phone <Link to="tel:020812444337">0208 12444337</Link></p>
                            </div>
                            <div className="footerwidget">
                                <h2>Find us on:</h2>
                                <div className="socailmediafooter">
                                    <Link to="https://www.facebook.com/FayyazTravels/" target="_blank"><FaFacebookF/></Link>
                                    <Link to="https://www.instagram.com/fayyaztravels/" target="_blank"><FaInstagram/></Link>
                                    <Link to="https://twitter.com/FayyazTravels/" target="_blank"><FaXTwitter/></Link>
                                    <Link to="https://sg.linkedin.com/company/fayyaz-travels-pte-ltd/" target="_blank"><FaLinkedinIn/></Link>
                                    <Link to="https://www.youtube.com/channel/UCjD1tI0YGDQQXqbfhYXP91g/" target="_blank"><FaYoutube/></Link>
                                    <Link to="https://www.pinterest.ph/FayyazTravels/" target="_blank"><FaPinterest/></Link>
                                    <Link to="https://www.tiktok.com/@fayyaztravels/" target="_blank"><FaTiktok/></Link>
                                </div>
                            </div>
                            <div className="footerwidget mb0">
                                <div className="footerlogo">
                                    <img src={footerlogo} alt="Footer Logo" />
                                    <h3>Dedicated Service, Tailored for you</h3>
                                    <p>Founded on our own love of travel, Fayyaz Travels continues to welcome travelers consumed by wanderlust into the family, keeping that streak of passion burning bright.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <section className="footerbottom">
            <div className="container-fluid">
                <div className="w90">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                            <div className="copyright">
                                <p>© 2024 - Fayyaz Travels - All Rights Reserved</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                            <div className="footerbottommenu">
                                <ul>
                                    <li>
                                        <Link to="https://fayyaztravels.com/covid-policies/" target="_blank">Covid Policies</Link>
                                    </li>
                                    <li>
                                        <Link to="https://fayyaztravels.com/terms-and-conditions/" target="_blank">Terms and Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to="https://fayyaztravels.com/privacy-policy/" target="_blank">Privacy Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}