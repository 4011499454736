import React from "react";
import "./home.css";
import Navbar from "../../Components/Navbar/navbar";
import Banner from "../../Components/Banner/banner";
import Tranding from "../../Components/Tranding/tranding";
import Hotdeals from "../../Components/Hotdeals/hotdeals";
import Services from "../../Components/Services/services";
import Testimonials from "../../Components/Testimonials/testimonials";
import Whychoose from "../../Components/Whychoose/whychoose";
import Subscribe from "../../Components/Subscribe/subscribe";
import Footer from "../../Components/Footer/footer";
import Featured from "../../Components/Featuredin/faturedin";
import Instagram from "../../Components/Instagram/instagram";

export default function Home(){
    return(
        <>
        <Navbar/>
        <Banner/>
        <Tranding/>
        <Hotdeals/>
        <Services/>
        <Testimonials/>
        <Whychoose/>
        <Instagram/>
        <Subscribe/>
        <Featured/>
        <Footer/>
        </>
    )
}