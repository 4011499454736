import React from "react";
import './preloader.css';
import Loader from "../../assets/loaderfayyaz.gif";
export default function Preloader(){
    return(
        <>
            <section className="preloader">
                <img src={Loader} alt="preloader" />
            </section>
        </>
    )
}