import React from "react";
import "./subscribe.css";

export default function Subscribe(){
    return(
        <>
        <section className="subscibe">
            <div className="container-fluid">
                <div className="w90">
                    <div className="row">
                        <div className="col-xxl-4 col-lg-6 col-md-8 col-sm-6 col-xs-12 col-12">
                            <div className="subscribebox">
                                <div className="subscibecontent">
                                    <h2>Subscribe to our Blog</h2>
                                    <p>Stay up-to-date with the latest news and insights by subscribing to our blog and newsletter with just your email</p>
                                </div>
                                <div className="subscribeform">
                                    <form action="" method="post">
                                        <input type="text" name="emailid" placeholder="Enter email Here" />
                                        <button type="submit">Subscribe</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}